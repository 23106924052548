import React from "react"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import * as forOurConsumersStyles from "./ForOurConsumers.module.scss"
import { Link } from "gatsby"

const ForOurConsumers = ({ pageContext, location }) => {
  const pageTitle = "For Our Consumers"

  return (
    <Layout>
      <Seo title={pageTitle} />
      {/* description for search only, hidden from document and screen readers */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        Our focus on enhancing life’s experiences stimulates our relentless
        drive to design and produce the best, innovative, comfortable footwear
        and apparel. Understanding what consumers need and want is at the heart
        of our product and brand portfolio development efforts.
      </p>
      {/* end hidden description */}
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div
        className={`inner-container-alt inner-y-padding ${forOurConsumersStyles.contentWrapper}`}
      >
        <div className={forOurConsumersStyles.committed}>
          <h2
            className="h4"
            style={{
              maxWidth: "50rem",
              marginBottom: "3rem",
              lineHeight: "1.3",
            }}
          >
            Our focus on enhancing life’s experiences stimulates our relentless
            drive to design and produce the{" "}
            <em style={{ color: "#9d5918" }}>
              best, innovative, comfortable footwear
            </em>{" "}
            and apparel. Understanding what consumers need and want is at the
            heart of our product and brand portfolio development efforts.
          </h2>
        </div>
        <nav
          className={forOurConsumersStyles.resources}
          aria-label="For Consumers"
        >
          <ul>
            <li>
              <Link to="/brands" className="biggerLink" title="Shop our brands">
                Shop Our Brands
              </Link>
            </li>
            <li>
              <Link
                to="/customer-resources/product-warranty"
                className="biggerLink"
                title="Product Warranty"
              >
                Rocky Brands Product Warranty
              </Link>
            </li>
            <li>
              <Link
                to="/about-us/contact-us"
                className="biggerLink"
                title="Contact Us"
              >
                Contact Us
              </Link>
            </li>

            <li>
              <a
                href="/docs/dealer-resources/EU-UK-safety-footwear-user-instruction-2022.pdf"
                target="__blank"
                className="biggerLink"
                title="EU/UK Safety Footwear User Instructions"
              >
                EU and UK Safety Footwear User Instructions
              </a>
            </li>
            <li>
              <Link
                to="/customer-resources/ca-transparency-statement"
                className="biggerLink"
                title="CA Transparency in Supply Chains Act Statement"
              >
                CA Transparency in Supply Chains Act Statement
              </Link>
            </li>
            <li>
              <a
                href="/docs/dealer-resources/UK-and-EU-Declarations-of-Conformity-–-Muck-Boot-Co.zip"
                target="__blank"
                className="biggerLink"
                title="UK and EU Declarations of Conformity - Muck Boot Co"
              >
                UK and EU Declarations of Conformity - Muck Boot Co
              </a>
            </li>
            <li>
              <a
                href="/docs/dealer-resources/XESCSA-EU-UK-Declaration-Of-Conformity.pdf"
                target="__blank"
                className="biggerLink"
                title="UK and EU Declarations of Conformity - XTRATUF Swingsaw"
              >
                UK and EU Declarations of Conformity - XTRATUF Swingsaw
              </a>
            </li>
            <li>
              <a
                href="/docs/dealer-resources/MNXTS5-declaration-of-conformity.pdf"
                target="__blank"
                className="biggerLink"
                title="UK and EU Declarations of Conformity - XTRATUF MNXTS5"
              >
                UK and EU Declarations of Conformity - XTRATUF MNXTS5
              </a>
            </li>
            <li>
              <a
                href="/docs/dealer-resources/MNXTS5-UKCA-certificate.pdf"
                target="__blank"
                className="biggerLink"
                title="UKCA Type Examination Certificate - MNXTS5"
              >
                UKCA Type Examination Certificate - MNXTS5
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </Layout>
  )
}

export default ForOurConsumers

export const Head = () => {
  return (
    <>
      <title data-typesense-field="title">For Our Consumers</title>
      <meta
        name="description"
        content="Our focus on enhancing life’s experiences stimulates our relentless drive to design and produce the best, innovative, comfortable footwear and apparel. Understanding what consumers need and want is at the heart of our product and brand portfolio development efforts."
      ></meta>
    </>
  )
}
